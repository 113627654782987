<template>
  <div v-if="exames && exames.length > 0">
    <v-dialog v-model="dialog" persistent max-width="1024">
      <v-card flat>
        <v-card-title>
          <span class="headline primary--text">Direcionamento Médico</span>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col>
              <v-autocomplete
                v-model="medicoSelected"
                :items="medicos"
                dense
                filled
                rounded
                clearable
                return-object
                :hide-details="true"
                label="Selecionar Médico"
                item-text="nomeExibicao"
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto">
              <v-btn
                x-large
                rounded
                color="primary"
                class="text-capitalize"
                :disabled="!medicoSelected"
                @click="addDirecionamento(medicoSelected)"
              >Direcionar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card outlined class="ma-2" v-for="exame in exames" :key="exame.id">
          <v-card-subtitle>
            <v-row dense>
              <v-col cols="12" md="2">
                <v-tooltip open-delay="800" top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-on="!isItToday(exame.data) ? on : false"
                      v-bind="attrs"
                    >{{ getDateFormatted(exame.data) }}</span>
                  </template>
                  <span> {{ getDateFormattedDisplay(exame.data) }} </span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="4">{{ $_.startCase(exame.solicitacao.paciente.nome.toLowerCase()) }}</v-col>
              <v-col cols="12" md="4">{{ exame.solicitacao.setor.unidade.nome }}</v-col>
              <v-col cols="12" md="2" class="text-end">
                <v-chip dark small label style="width: 125px" class="justify-center"
                  :color="statusColor(`${exame.statusExame.id}`) + ' white--text'"
                >
                  <span>{{ exame.statusExame.descricao }}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-list-item dense class="px-0" v-for="item in exame.exameDirecionados" :key="`${item.id.exame.id}_${item.id.medico.id}`">
              <v-list-item-avatar>
                <v-avatar
                  color="primary"
                  size="36"
                  class="white--text"
                >{{ `${item.id.medico.nomeExibicao[0]}` }}</v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                {{ item.id.medico.nomeExibicao }}
              </v-list-item-content>

              <v-list-item-content>
                {{ dateFormat(item.dataCriacao) }}
              </v-list-item-content>

              <v-list-item-action>
                <v-btn class="text-capitalize" color="error" small height="24" @click="removeDirecionamento(item)">
                  <span>Excluir</span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            width="110"
            color="error"
            class="text-capitilize darken-1"
            @click="close"
          >Sair</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getPerfilId } from '../../seguranca';
import { dateFormat, getDateFormatted, getDateFormattedDisplay, isItToday } from '../../utils/dateUtils';

export default {
  name: "exame-direcionamento-medico",

  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    exames: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      medicos: [],
      medicoSelected: null,
    };
  },
  watch: {
    value(value) {
      this.dialog = value;
    },
    dialog(value) {
      this.$emit("input", value);
    },
  },
  methods: {
    ...mapActions(["setAlerta"]),
    close() {
      this.medicoSelected = null
      this.dialog = false;
    },
    async addDirecionamento(value) {
      const self = this

      // Cria os objetos exameDirecionado para cada um dos exames selecionados
      const direcionamentos = self.exames.map(exame => ({ id: { exame: {id: exame.id}, medico: {id: value.id} }, usuarioCriacao: { id: getPerfilId() } }));
      
      if (direcionamentos && direcionamentos.length > 0) {
        await Promise.all(direcionamentos.map(async (direcionamento) => {

          const direcionamentoSaved = await self.$http.post(`/exameDirecionado`, direcionamento)
            .then(response => response.data)
          
          const newExame = await self.$http.get(`/exame/${direcionamentoSaved.id.exame.id}`)
            .then(response => response.data)
          
          return newExame
        }))
        .then(newExames => {
          newExames.forEach(newExame => {
            const index = self.exames.findIndex(k => k.id == newExame.id)
            self.exames.splice(index, 1, newExame)
          })
          self.$emit("objectReturn", self.exames)
        })
      }
    },
    async removeDirecionamento(value) {
      const self = this

      self.$http
        .delete(`/exameDirecionado/${value.id.exame.id}/${value.id.medico.id}`)
        .then(() => {
          const index = self.exames.findIndex(k => k.id == value.id.exame.id)
          const direcionados = Array.from(self.exames[index].exameDirecionados)
          self.exames[index].exameDirecionados.splice(direcionados.indexOf(value), 1)
          self.$emit("objectReturn", self.exames)
        })
        .catch(() => {
          console.log("Falha ao deletar direcionamento: ", value);
        })
    },
    buscarMedicos() {
      const self = this;
      const authorities = [
        'ROLE_MEDICO_ATENDIMENTO',
        'ROLE_MEDICO_RADIOLOGISTA'
      ]
      this.$http
        .get(`/perfil?authority=${authorities}&max=-1&sort=nomeExibicao`)
        .then((response) => {
          this.medicos = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Médicos",
          });
        });
    },
    isItToday(date) {
      return isItToday(date)
    },
    dateFormat(date) {
      return dateFormat(date, null, "DD/MM/YYYY HH:mm")
    },
    getDateFormatted(date) {
      return getDateFormatted(date)
    },
    getDateFormattedDisplay(date) {
      return getDateFormattedDisplay(date)
    },
    statusColor(status) {
      return status == "1"
        ? "green"
        : status == "2"
        ? "primary"
        : status == "3"
        ? "red"
        : status == "4"
        ? "blue"
        : status == "5"
        ? "orange"
        : "";
    },
  },
  created() {
    this.buscarMedicos();
  },
  destroyed() {
    this.close()
  }
};
</script>